<template>
  <div class="table">
    <v-data-table
      :search="search"
      :headers="headers"
      :items="items"
      :items-per-page="14"
      class="elevation-1"
    >
      <template v-slot:header.name="{ header }">
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:item.actions="{item}">
        <v-icon  title="Ban" class="mx-2" v-if="bannable" @click="ban(item)" color="#E74C3C"> mdi-cancel </v-icon>
        <v-icon  title="Edit" @click="edit(item)" color="#3498DB" class="mx-2" > mdi-pencil </v-icon>
        <v-icon  title="Remove" class="mx-2" @click="remove(item)" color="#E74C3C"> mdi-delete </v-icon>
      </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    search: String,
    headers: Array,
    items: Array,
    bannable: Boolean
  },

  methods: {
    edit(item) {
      const id = this.items.indexOf(item)
      this.$emit('edit', id)
    },

    ban(item) {
      const id = this.items.indexOf(item)
      this.$emit('ban', id)
    },

    remove(item) {
      const id = this.items.indexOf(item)
      this.$emit('remove', id)
    }
  },


  data: () => ({
  }),
};
</script>

<style lang="scss">
.v-data-table {
  background: none !important;
}
</style>