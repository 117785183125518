<template>
  <div>
    <SectionHeading
      :content="heading.content"
      :extra_content="heading.extra"
      @search="onSearch"
      @btnClick="onNewRowClick"
    />
    <Table
      :search="search"
      :headers="headers"
      :items="getFormatRows"
      :bannable="false"
      @edit="onRowEdit"
      @remove="onRowRemove"
    />

    <v-dialog v-model="rmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Remove this row? </v-card-title>
        <v-card-text>Are you sure you want to remove this row?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"> No </v-btn>
          <v-btn text @click="saveRmData"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="450" v-model="addDialog">
      <v-card>
        <v-card-title>{{ dialogHeader }}</v-card-title>

        <v-divider />

        <v-card-text>
          <v-form class="mt-5">
            <v-text-field :disabled="true" label="ID" v-model="entry.id" />
            <v-text-field label="Name" v-model="entry.name" />
            <v-text-field label="Description" v-model="entry.description" />
            <v-switch label="API Access" v-model="entry.api_access" />
            <v-text-field label="Price ($)" v-model="entry.price" />
            <v-text-field label="Duration (secs)" v-model="entry.duration" />
            <v-text-field label="Query Limit" v-model="entry.raw_query_limit" />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDialog" text> Cancel </v-btn>
          <v-btn
            v-if="dialogAction == 'edit'"
            @click="saveEditData"
            color="primary"
            text
            >Save</v-btn
          >
          <v-btn
            v-if="dialogAction == 'new'"
            @click="saveNewData"
            color="primary"
            text
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SectionHeading from "../../components/SectionHeading.vue";
import Table from "../components/Table.vue";
export default {
  name: "Subscriptions",

  components: {
    Table,
    SectionHeading,
  },

  async mounted() {
    let fetched = await this.$http
      .get("/admin/plans/all")
      .then((res) => res.data);

    this.rows = fetched.plans;
  },

  computed: {
    getFormatRows() {
      let cpyRows = JSON.parse(JSON.stringify(this.rows));
      return cpyRows.map((e) => {
        return Object.assign(e, {
          duration: Math.floor(e.duration / 60 / 60 / 24) + " days",
          api_access: e.api_access == 1 ? "Yes" : "No",
          price: "$" + e.price,
        });
      });
    },
  },

  methods: {
    async saveRmData() {
      try {
        await this.$http
          .post("/admin/plans/delete", { plan_id: this.entry.id })
          .then((res) => res.data);
        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "Your changes were saved",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Couldn't process your request",
        });
      }

      this.closeDialog();
    },

    async saveEditData() {
      this.entry.api_access = this.entry.api_access == true ? 1 : 0;

      try {
        await this.$http
          .post("/admin/plans/edit", this.entry)
          .then((res) => res.data);
        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "Your changes were saved",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Couldn't process your request",
        });
      }

      this.closeDialog();
    },

    async saveNewData() {
      this.entry.api_access = this.entry.api_access == true ? 1 : 0;

      try {
        await this.$http
          .post("/admin/plans/create", this.entry)
          .then((res) => res.data);

        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "Your changes were saved",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Couldn't process your request",
        });
      }

      this.closeDialog();
    },

    pickDate(date) {
      this.$refs.dialog.save(date);

      this.entry.plan_expiry = Math.floor(new Date(date).getTime() / 1000);
    },

    closeDialog() {
      this.addDialog = false;
      this.rmDialog = false;
      this.banDialog = false;

      this.entry = {};
    },

    onSearch(e) {
      this.search = e;
    },

    onNewRowClick() {
      this.dialogHeader = "Create Plan";
      this.addDialog = true;
      this.dialogAction = "new";
    },

    onRowEdit(id) {
      this.dialogAction = "edit";
      this.dialogHeader = "Edit Plan";
      this.entry = this.rows[id];
      this.addDialog = true;

      console.log(id)
    },

    onRowRemove(id) {
      this.rmDialog = true;
      this.entry = this.rows[id];
    },

    onRowBan(id) {
      this.entry = this.rows[id];
      this.banDialog = true;
    },
  },

  data() {
    return {
      datePickerOpened: false,

      dialogAction: "",

      rmDialog: false,
      addDialog: false,
      dialogHeader: null,
      deleteDialog: false,

      entry: {},

      heading: {
        content: "Subscriptions",
        extra: [
          {
            type: "button",
            text: "Create Row",
            color: "#3498DB",
            font_color: "#FFFFFF",
            icon: "mdi-database-plus-outline",
          },
          { type: "search" },
        ],
      },

      search: "",

      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "API Access", value: "api_access" },
        { text: "Price", value: "price" },
        { text: "Duration", value: "duration" },
        { text: "Query Limit", value: "raw_query_limit" },
        { text: "Actions", value: "actions" },
      ],
      rows: [],
    };
  },
};
</script>

<style lang='scss'>
</style>